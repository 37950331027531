import React from 'react';
import PropTypes from 'prop-types';
import api from '@tutorbot/directory-api';
import { setCookie } from '../utils/cookies';
import { getDomainSettings, getDomainsSettings } from '../utils/api';

export const SettingsContext = React.createContext();

export const SettingsProvider = ({ children, domainSettings: propsDomainSettings }) => {
  const { id, domain, createdAt, updatedAt, __v,
    isRegisterRewardEnabled, rewardUsePercent, registerRewardAmount, registerRewardCurrency,
    ...otherDomainSettings } = propsDomainSettings;

  const [domainSettings] = React.useState({
    cookie_enabled: false,
    cookie_overlay: false,
    course_finder: true,
    school_finder: false,
    privacy_url: '',
    location: {},
    facebook: {},
    google: {},
    domain,
    id,
    feature_enableGuidePaths: false,
    featureCommunity: false,
    showBlog: false,
    featureTermsAndConditions: false,
    isRegisterRewardEnabled,
    rewardUsePercent,
    registerRewardAmount,
    registerRewardCurrency,
    ...otherDomainSettings,
  });

  const enableCookie = () => { };
  const isRegisterRewardEnabledAndConfigured = isRegisterRewardEnabled && rewardUsePercent && registerRewardAmount && registerRewardCurrency && true
  const baseUrl = `http${domainSettings.isHttpsSslSecured ? 's' : ''}://${domainSettings.isWwwEnabled ? 'www.' : ''}${domainSettings.domain}${domainSettings.domain === 'localhost' ? ':3000' : ''}`

  return (
    <SettingsContext.Provider value={{ domainSettings, enableCookie, isRegisterRewardEnabledAndConfigured, baseUrl }}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.any,
  domainSettings: PropTypes.any.isRequired,
};

SettingsProvider.defaultProps = {
  children: null,
};

SettingsProvider.getInitialProps = async (ctx, router) => {
  if (process.browser) {
    const { pathname, components } = router;

    if (components[pathname] && components[pathname].props) {
      return {
        ...components[pathname].props.settingsProps,
      };
    }
  }

  const {
    req: { headers },
  } = ctx;

  const host = headers['x-forwarded-for'] || headers.host;

  const domainsSettings = await getDomainsSettings();
  const domainSettings = await getDomainSettings(host);

  if (domainSettings) {
    setCookie('portal', domainSettings.id);
  }

  return {
    domainSettings,
    domainsSettings
  };
};
